<template>
  <div>
    <div class="register-page form">
      <div class="container">
        <div class="form__title">
          <h1>C'est parti !</h1>
          <div class="form__top">
            <ProgressBar :current="currentSlide" :total="questions.length"/>
          </div>
        </div>
        <div class="form-container">

          <ValidationObserver v-slot="{ handleSubmit }" ref="signUpObserver" tag="form" @submit.prevent>
            <NTypeform class="typeform" :currentSlide="currentSlide">
              <template v-slot:slides>
  
  
                <NTypeformSlide ref="emailSlide" :index="1">
                  <template>
                    <NText
                      data-cy="input-email"
                      ref="input-email"
                      id="input-email"
                      errorTrigger="blur"
                      :name="$t('register.form.email').toLowerCase()"
                      type="email"
                      v-model="signupForm.model.sEmail"
                      :rules="`required|email|already_registered:${user_already_exist}`"
                      animationType="above"
                      placeholder="Ex: jean@gmail.com"
                      :designType="panoplyStyle.inputs.designType"
                      :borderColor="colorStyle.primary"
                      :errorColor="colorStyle.error"
                      @isValid="(isValid) => (validity.email = isValid)"
                    >Quel est votre e-mail ?</NText>
                    <br><br>
                    <p class="mentions">En validant votre inscription, vous acceptez de recevoir par email des offres commerciales de Ma&nbsp;French&nbsp;Bank et des sociétes du groupe La&nbsp;Poste.</p>
  
                    <!-- <NTypeformCta v-if="validity.email" @click.native="handleSubmit(submit)">Ok</NTypeformCta> -->
                  </template>
                </NTypeformSlide>
                <NTypeformSlide :index="2">
                  <template>
                    <div class="mt-8">
                      <NText
                        data-cy="input-firstname"
                        id="input-firstname"
                        :name="$t('register.form.first_name').toLowerCase()"
                        type="text"
                        v-model="signupForm.model.sFirstName"
                        :rules="currentSlide === 1 ? 'required|nameType' : ''"
                        animationType="above"
                        :designType="panoplyStyle.inputs.designType"
                        :borderColor="colorStyle.primary"
                        :errorColor="colorStyle.error"
                        placeholder="Ex: Jean"
                        @isValid="(isValid) => (validity.firstname = isValid)"
                      >Quel est votre prénom ?</NText>
                    </div>
                  </template>
                </NTypeformSlide>
  
                <NTypeformSlide :index="3">
                  <template>
                    <NMask
                      data-cy="input-birthday"
                      id="input-birthday"
                      :name="$t('register.form.birthday').toLowerCase()"
                      maskType="tel"
                      mask="##/##/####"
                      :rules="currentSlide === 2 ? 'required|validDate|ageMin:15' : ''"
                      errorTrigger="blur"
                      placeholder="Ex: 04/01/1988"
                      v-model="signupForm.model.oResponses.dBirthDate_1"
                      animationType="label"
                      labelColor="red"
                      :designType="panoplyStyle.inputs.designType"
                      :borderColor="colorStyle.primary"
                      :errorColor="colorStyle.error"
                      @isValid="(isValid) => (validity.birthday = isValid)"
                    >{{signupForm.model.sFirstName}}, pouvez-vous nous donner votre date de naissance ?</NMask>
                  </template>
                </NTypeformSlide>
              </template>
            </NTypeform>
  
            <!-- <NTypeformCta @click.native="if(currentSlide == 2) {handleSubmit(submit)} else {nextSlide()}">OK, JE VALIDE</NTypeformCta> -->
  
            <NTypeformCta @click.native="handleSubmit(nextSlide)">OK, JE VALIDE</NTypeformCta>
          </ValidationObserver>
          <div class="form__wrapper" :style="{ height: '500px' }">
            <Illustration 
              v-for="question of questions" 
              :id="question.id" 
              :current="currentSlide" 
              :key="question.id + 10" 
            />
          </div>
        </div>
      </div>
    </div>
    <div id="captcha">
      <vue-recaptcha
        ref="recaptcha"
        id="captcha_id"
        sitekey="6Lf0uhwUAAAAAAZQK0AfIcp6wMgQNO_EOoFg9zND"
        @verify="onVerify"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/modules/form/ProgressBar'
import Illustration from '@/components/modules/form/Illustration'
import VueRecaptcha from 'vue-recaptcha'

import {
  NText,
  NMask
} from '@team-uep/n-inputs'

import {
  NTypeform,
  NTypeformSlide,
  NTypeformCta,
} from "@team-uep/n-typeform";

import { mapGetters } from 'vuex'
import registerForm from '../mixins/registerForm'
import { removeSpaces } from '@/helpers'
import design from '@/validators/design'

export default {
  name: 'RegisterTypeForm',
  mixins: [registerForm],
  components: {
    ProgressBar,
    Illustration,
    NText,
    NMask,
    NTypeform,
    NTypeformSlide,
    NTypeformCta,
    VueRecaptcha,
  },
  props: {
    relogEmailSent: Boolean,
    displayRelog: Boolean,
    user_already_exist: Boolean
  },
  data () {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
      is_loading: false,
      signupForm: {
        terms: null,
        model: {
          oResponses: {
            dBirthDate_1: ''
          },
          sEmail: '',
          sFirstName: '',
          oOptins: {
            iOptin_2: true
          }
        }
      },
      relogForm: {
        model: {}
      },
      currentSlide: 0,
      slides: [
        "email",
        "firstname",
        "birthday",
      ],
      validity: {
        email: false, //3
        firstName: false, //1
        birthday: false, //2
      },
      questions: [{id: 1},{id: 2},{id: 3}],
    }
  },
  computed: {
    ...mapGetters({
      stateParams: 'user/stateParams'
    })
  },
  mounted() { 
    // URL params prefill on register form
    const urlParams = this.stateParams || this.$route.query;
    const inputEmailRef = this.$refs['input-email'];

    urlParams.e != null ? this.signupForm.model.sEmail = urlParams.e : null
    urlParams.p != null ? this.signupForm.model.sFirstName = urlParams.p : null
    urlParams.n != null ? this.signupForm.model.sLastName = urlParams.n : null
    urlParams.c != null && (urlParams.c === '1' || urlParams.c === '2') ? this.signupForm.model.iCivilId = parseInt(urlParams.c) : null
 

    // Disable email already used error message on email-input focus
    if (inputEmailRef && inputEmailRef.$el) {
      const inputEmailRefNode = (inputEmailRef.$el.getElementsByTagName('input') || [])[0];

      if (inputEmailRefNode) {
        inputEmailRefNode.onfocus = () => {
          this.$emit('update:user_already_exist', false);
        }
      }
    } 
  },

  destroyed() {
    window.removeEventListener("keyup", this.pressEnter, false);
  },
  methods: {
    pressEnter(e) {
      if (e.keyCode === 9) {
        this.nextSlide();
      }
    },
    checkSlideAuth(num) {
      const next = Math.min(this.slides.length - 1, Math.max(0, num));
      for (let i = 0; i < next; i++) {
        const slideName = this.slides[i];
        if (!this.validity[slideName]) {
          return false;
        }
      }
      return true;
    },
    goSlide(num) {
      if (this.checkSlideAuth(num) && num <= this.slides.length) {
        this.currentSlide = num;
        if(num == 1) {
          this.tcLoadTracking({'service': 'inscription_newsletter', 'page_category': 'adresse_email'})
        }
      } else {
        if(num == 2) {
          this.$children[1].$children[0].$children[0].$children[1].$children[0].$refs.inputField.$el.blur()
        } 
        var form = this.$children[0].$children[0].$children[0]
        var elem = form.$children[this.currentSlide].$children[0]
        if(elem.$refs.inputField) {
          if (this.currentSlide >= 3) {
            window.setTimeout(() => {
              elem.$refs.inputField.$el.focus()
            }, 550)
          } else {
            window.setTimeout(() => {
              elem.$refs.inputField.focus()
            }, 550)
          }
        }
      }
    },
    prevSlide() {
      this.goSlide(this.currentSlide - 1);
    },
    nextSlide() {
      if (this.currentSlide === 0) {
        // inscription/register
        this.postRegister()
      } else if (this.currentSlide === 1) {
        this.postUpdate()
      } else if (this.currentSlide === 2) {
        this.postQualif()
      } else {
        this.goSlide(this.currentSlide + 1);
      }
      
    },

    submit () {
      this.$emit('submit', this.signupForm);
    },
    submitRelog () {
      this.$emit('submitRelog', this.relogForm);
    },
    resetForm () {
      this.$refs.signUpObserver.reset()
    },
    
    postRegister () {
      this.is_loading = true
      // In case we need to update the data before sending them
      let userData = JSON.parse(JSON.stringify({ sEmail: this.signupForm.model.sEmail, oOptins: {iOptin_2: true} }));

      this.$requestAPI.register(userData)
        .then(() => {
          this.$mmTro.conversion(this.$route.query)
          this.goSlide(this.currentSlide + 1);
          this.resetForm()
        })
        .catch((error) => {
          // Test if error is an ApiError object
          if (error.name === 'ApiError') {
            if (error.response && error.response.status === 503) {
              const onRecaptcha = () => {
                this.$off("recaptcha-valid", onRecaptcha);
                this.postRegister();
              }

              // trick to keep signupForm in cache
              // create a listenner on recaptcha 
              this.$on("recaptcha-valid", onRecaptcha);

              this.$refs.recaptcha.reset()
              this.$refs.recaptcha.execute()
            }

            if (error.response && error.response.status === 409) {
              this.user_already_exist = true
            } else if (error.response.status !== 503) {
              console.log('option consentement_non_coche')
              this.tcLoadTracking({ 'service': 'inscription_newsletter', 'page_category': 'optin', 'page_name': 'erreur', 'type_erreur': 'consentement_non_coche' })
            }
          } else {
            this.tcLoadTracking({ 'service': 'inscription_newsletter', 'page_category': 'optin', 'page_name': 'erreur', 'type_erreur': 'consentement_non_coche' })
          }
          this.is_loading = false
        });
    },

    postUpdate() {
      this.is_loading = true
      // In case we need to update the data before sending them
      let userData = JSON.parse(JSON.stringify({ sLastName: this.signupForm.model.sFirstName }));

      this.$requestAPI._post('inscription/update', userData)
        .then(() => {
          this.goSlide(this.currentSlide + 1);
          this.resetForm()
        })
        .catch((error) => {
          this.is_loading = false
        })
    },

    postQualif() {
      this.is_loading = true
      // In case we need to update the data before sending them
      let userData = JSON.parse(JSON.stringify({ oResponses: { dBirthDate_1: this.signupForm.model.oResponses.dBirthDate_1 } }));

      this.$requestAPI._post('qualif/register', userData)
        .then(() => {
          this.$router.push({ name: this.$route.meta.nextPage })
            .catch(console.log);
        })
        .catch((error) => {
          this.is_loading = false
        })
    },

    onVerify: function (response) {
      console.log('Verify: ' + response)
      this.sendCaptchaResult(response)
    },

    sendCaptchaResult (result) {
      this.$requestAPI.sendCaptchaToken({
        sBlockedUrl: [
          `${document.location.protocol}//${document.location.host}/api/v0/`,
          `${this.appConfig.baseName}/${this.appConfig.activeDecli.apiVersion}`,
          `/inscription/register`
        ].join(''),
        sCaptchaResponse: result,
      })
        .then(() => {
          this.$emit('recaptcha-valid')
        })
        .catch(console.log);
    },
    /*eslint-disable*/
    tcLoadTracking (options) {
      tc_events_global(this, 'PAGELOAD', options)
    },
    tcClickTracking (options) {
      tc_events_global(this, 'CLICK', options)
    }
    /*eslint-enable*/
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.typeform ::v-deep {
  width: 425px;
  margin: auto;
  .slide-container {
    height: 220px !important;
  }
  .slide {
    height: 220px !important;
  }
  @media (max-width: 700px) {
    width: 325px !important;
  }
}

.n-typeform-cta ::v-deep {
  background-color: #00b4b4;
  z-index: 9999;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  text-decoration: none;
  height: 50px;
  min-width: 150px;
  text-align: center;
  font-family: Oswald;
  font-weight: 700;
  font-size: 16px;
  margin-top: 0.5rem !important;
  margin-left: 14rem;
  @media (max-width: 400px) {
    margin-left: 10rem;
  }
  svg {
    display: none;
  }
}

::v-deep .n-input.n-text,
.n-input.n-radios,
.n-input.n-checkbox {
  // reset property
  @apply py-3 text-md min-h-0;
}

.n-input__radio-label /deep/{
  padding-right: 25px;
}

.n-checkbox /deep/{
  margin-top: 1rem;
  .n-input__checkbox {
    height: 26px;
  }
  .n-input__error-text {
    text-align: center;
  }
}

.n-input ::v-deep {
  input {
    font-family: 'Lato' !important; 
    background-color: transparent !important;
    border-color: rgba(0,69,87,0.2) !important;
    padding: 0px !important;
    color: #00565a !important;
    font-size: 21px !important;
    @media (max-width: 700px) {
      font-size: 18px !important;
    }
  }
  ::placeholder {
    font-family: 'Lato' !important;
    font-weight: bold;
    font-size: 20px !important;
    color: rgba(0,69,87,0.4);
    opacity: 0.5;
    @media (max-width: 700px) {
      font-size: 18px !important;
    }
  }
  .n-input__label-text {
    font-family: 'Lato' !important; 
    font-weight: bold;
    font-size: 23px !important;
    color: #00565a !important;
    @media (max-width: 700px) {
      font-size: 18px !important;
    }
  }
  .n-input__checkbox {
    margin-top: 0.35rem;
  }
  .n-input__error-text {
    font-family: 'Lato' !important;
    font-weight: bold;
    font-size: 14px !important;
    font-style: italic;
  }
  .n-input__error-icon span {
    display: none;
  }
  .n-input__checkmark-label {
    font-family: 'Lato' !important; 
    font-size: 20px !important;
    color: #00565a !important;
    line-height: 1.3 !important;
  }
  .n-input__checkbox {
    max-width: 25px;
    .n-input__checkmark {
      height: 25px;
      width: 25px;
      border: 2px solid black !important;
      border-radius: 0px;
    }
  }
  .n-input__bottom-area {
    float: right;
    margin-top: 1rem !important;
  }
  .n-input__error-text {
    span {
      font-family: 'Lato' !important;
      font-weight: bold;
      font-size: 14px;
      font-style: italic;
    }
  }
}

form {
  margin-top: 3rem;
  height: 100%;
  width: 100%;
}

.register-page {
  background-color: white;

  .container {
    padding:0rem 3rem;
    width: 100%;
    z-index: 998;
    overflow-x: hidden;
    @media (min-width: 700px) {
      overflow: hidden;
    }
    img.parallax {
      position: absolute;
      right: 50px;
      top: 160px;
      @media #{$small} {
        display: none;
      }
    }
    @media #{$small} {
      padding: 0rem 1rem;
    }
  }
}

#captcha{
  visibility: hidden;
  position: absolute;
}

.form {
  position: relative;
  display: flex;
  max-width: 920px;
  height: 514px;
  width: 100%;
  justify-content: center;
  margin: auto;
  @media #{$medium} {
    width: 100%;
  }

  @media #{$small} {
    width: 100%;
    height: 450px;
  }

  .form-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    @media (max-width: 700px) {
      display: none;
    }

    .introduction {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;

      .top {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: row;  
        .left, .right {
          flex: 1;
        }

        @media #{$small} {
          flex-direction: column;
        }

        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 30px;
          margin-top: 30px;
          @media #{$small} {
            border: none;
            justify-content: center;
            align-items: center;
            padding-right: 0px;
          }
        }

        .right {
          border-left: 2px solid #D8D8D8;
          padding-left: 30px;
          @media #{$small} {
            border: none;
            padding: 0;
          }
          .tagline {
            display: flex;
            flex-direction: row;
            height: 63px;
            justify-content: flex-start;
            align-items: center;
            img {
              margin-right: 20px;
            }
          }
        }

        img.desktop {
          // width: 687px;
          max-height: 189px;
          min-width: 281px;
          @media #{$small} {
            display: none;
          }
        }
        img.mobile {
          display: none;
          max-width: 270px;
          width: 100%;
          margin: auto;
          margin-bottom: 20px;
          @media #{$small} {
            display: block;
          }
        }
      }
    }
    form {
      margin-top: 150px;
      max-width: 50%;
      height: 100%;
      z-index: 999;
     
      .actions {
        position: absolute;
        top: 280px;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        @media #{$small} {
          justify-content: flex-end;
          align-items: center;
          flex-direction: column-reverse;
          top: 240px;
          width: 100%;
          min-height: 250px;
        }

        &.flexend {
          justify-content: flex-end;
        }
      }
      @media #{$small} {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    @media #{$small} {
      max-width: 100%;
    }
  }
  &__title {
    display: flex;
    min-height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    margin-top: 50px;
    width: 100%;
    h1 {
      text-align: center;
      font-size: 28px;
      color: $dark-green;
      font-weight: 900;
      font-family: 'Lato';
      margin-bottom: 20px;
    }
  }
  &__top {
    top: 70px;
    width: 100%;
    margin: auto;
    max-width: 280px;
    // left: 0;
    // right: 0;
    // margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.error_message {
  position: absolute;
  text-align: center;
  top: 240px;
  left: 20%;
  text-align: right;
  color: red !important;
  font-style: italic !important;
  z-index: 999;
  a {
    color: red;
    text-decoration: underline;
  }
  @media #{$small} {
    top: 275px;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
}
.single-question {
  padding-top: 1px;
}

.multiple-fields {
  &.current {
    margin-top: -5rem;
    .question {
      opacity: 1 !important;
      transform: translateY(0%)!important;
      min-height: 115px;
      pointer-events: inherit;
    }
  }
}

.mentions {
  font-size: 12px;
  font-family: 'Lato';
  color: #00565A;
}
</style>
