<template>
  <div class="progress-bar">
    <div class="progress-bar__gauge">
      <div
        class="progress-bar__bar"
        :style="{
          transform: `scaleX(${percent / 100})`
        }"
      /> 
    </div>
    <div class="progress-bar__bubble first" :class="{'active' : percent >= 0}"/>
    <div class="progress-bar__bubble second" :class="{'active' : percent >= 50}"/>
    <div class="progress-bar__bubble third" :class="{'active' : percent > 50}"/>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: ['current', 'total'],
  watch: {
    current: function (newVal) {
      this.percent = ((newVal) / this.total) * 100
      if (this.percent > 0 && this.percent < 50) {
        this.percent = 50
      } else if (this.percent > 50) {
        this.percent = 100
      }
    }
  },
  data: () => ({
    percent: 0
  })
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';
.progress-bar {
  position: relative;
  margin-top: 10px;
  width: 280px;
  &__gauge {
    position: relative;
    width: 280px;
    height: 1.5px;
    color: $dark-green;
    background-color: #C2CCD2;
    border-radius: 4px;
    overflow: hidden;

    // @media #{$small} {
    //   width: 50vw;
    // }
  }
  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-green;
    border-radius: 3px;
    transform-origin: left;
    transition: transform 600ms ease-out;
  }
  &__bubble {
    position: absolute;
    top: -8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #C2CCD2;
    border: 0px solid #C2CCD2;
    transition: all 200ms ease-out;
    transition-delay: 600ms;
    &.first {
      left: calc(0% - 11px);
    }
    &.second {
      left: calc(50% - 11px);
    }
    &.third {
      left: calc(100% - 11px);
    }
    &.fourth {
      left: calc(100% - 11px);
    }
    &.active {
      background-color: $dark-green;
      border: 2px solid $dark-green;
      box-shadow:0px 0px 0px 2px white inset;
    }
  }
}
</style>
)