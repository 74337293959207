<template>
  <div>
    <div v-if="this.visitId % 2 === 0">
      <register-type-form
        @submitRelog="submitRelog"
        :relogEmailSent="relogEmailSent"
        :displayRelog="displayRelog"
        @update:user_already_exist="user_already_exist = $event"
        :user_already_exist="user_already_exist"
      />
    </div>
    <div v-else>
      <register-type-form-ab
        @submit="postRegister"
        @submitRelog="submitRelog"
        :relogEmailSent="relogEmailSent"
        :displayRelog="displayRelog"
        :is_loading="is_loading" 
        @update:user_already_exist="user_already_exist = $event"
        :user_already_exist="user_already_exist"
      />

      <div id="captcha">
        <vue-recaptcha
          ref="recaptcha"
          id="captcha_id"
          sitekey="6Lf0uhwUAAAAAAZQK0AfIcp6wMgQNO_EOoFg9zND"
          @verify="onVerify"
          :loadRecaptchaScript="true"
          size="invisible"
        ></vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { removeSpaces } from '@/helpers'
  import design from '@/validators/design'
  import RegisterTypeForm from '@/components/RegisterTypeForm.vue'
  import RegisterTypeFormAb from '@/components/RegisterTypeFormAB.vue'

  export default {
    name: 'register',
    data () {
      return {
        design: design,
        displayRelog: false,
        relogEmailSent: false,
        user_already_exist: false,
        suggestions: [],
        needsSuggestions: false,
        blocked: false,
        is_loading: false,
      }
    },
    components: {  
      RegisterTypeForm,
      RegisterTypeFormAb,
    },
    methods: {
      // ...mapActions(['updateUserSession']),
      submitRelog (relogForm) {
        this.$requestAPI.sendRelogEmail({
          sEmail: relogForm.model.sEmail,
        })
          .catch(console.log)
          .finally(() => {
            this.relogEmailSent = true
            this.is_loading = false
          });
      },

      postRegister (signupForm) {
        this.is_loading = true
        // In case we need to update the data before sending them
        let userData = JSON.parse(JSON.stringify(signupForm.model));
        // Clean data before calling the API
        userData.sFirstName = removeSpaces(userData.sFirstName)

        this.$requestAPI.register(userData)
          .then(() => {
            this.$mmTro.conversion(this.$route.query)

            this.$router.push({ name: this.$route.meta.nextPage })
              .catch(console.log);
          })
          .catch((error) => {
            // Test if error is an ApiError object
            if (error.name === 'ApiError') {
              if (error.response && error.response.status === 503) {
                const onRecaptcha = () => {
                  this.$off("recaptcha-valid", onRecaptcha);
                  this.postRegister(signupForm);
                }

                // trick to keep signupForm in cache
                // create a listenner on recaptcha 
                this.$on("recaptcha-valid", onRecaptcha);

                this.$refs.recaptcha.reset()
                this.$refs.recaptcha.execute()
              }
  
              if (error.response && error.response.status === 409) {
                this.user_already_exist = true
              } else if (error.response.status !== 503) {
                console.log('option consentement_non_coche')
                this.tcLoadTracking({ 'service': 'inscription_newsletter', 'page_category': 'optin', 'page_name': 'erreur', 'type_erreur': 'consentement_non_coche' })
              }
            } else {
              this.tcLoadTracking({ 'service': 'inscription_newsletter', 'page_category': 'optin', 'page_name': 'erreur', 'type_erreur': 'consentement_non_coche' })
            }
            this.is_loading = false
          });
      },

      onVerify: function (response) {
        console.log('Verify: ' + response)
        this.sendCaptchaResult(response)
      },

      sendCaptchaResult (result) {
        this.$requestAPI.sendCaptchaToken({
          sBlockedUrl: [
            `${document.location.protocol}//${document.location.host}/api/v0/`,
            `${this.appConfig.baseName}/${this.appConfig.activeDecli.apiVersion}`,
            `/inscription/register`
          ].join(''),
          sCaptchaResponse: result,
        })
          .then(() => {
            this.$emit('recaptcha-valid')
          })
          .catch(console.log);
      },

      /*eslint-disable*/
      tcLoadTracking (options) {
        tc_events_global(this, 'PAGELOAD', options)
      },
      tcClickTracking (options) {
        tc_events_global(this, 'CLICK', options)
      }
      /*eslint-enable*/
    },
    computed: {
      ...mapActions(['updateModalConfig']),
      ...mapGetters({
        appConfig: 'config/appConfig',
        visitId: 'global/visitId',
      }),
    },
    mounted () {
      this.$mmTro.sendRtg()
      console.log('visitId: ', this.visitId);
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .typeform {
  .n-typeform-cta {
    display: flex;
  }
  .valid-icon {
    margin: auto;
    margin-left: 0.5rem;
  }
  .n-input input {
    padding-top: Orem;
  }
}
#captcha {
  visibility: hidden;
}
</style> 
