<template>
  <div class="illustration">
    <transition name="fade">
      <img class="picto" :src="require(`../../../assets/images/${localId}.png`)" v-if="id == current+1" :class="`__${id}`"/>
    </transition>
    <img class="bg" src="../../../assets/images/smartphone.png" />
    <img class="parallax" src="../../../assets/images/parallax_bg.png" v-if="id == current+1" :style="parallax(1.3)"/>
  </div>
</template>

<script>
  import Bowser from 'bowser'

  export default {
    name: 'Illustration',
    props: [
      'id',
      'current'
    ],
    data () {
      return {
        localId: 1,
        mouseX: 0,
        mouseY: 0
      }
    },
    mounted () {
      const browser = Bowser.getParser(window.navigator.userAgent)
      if (!browser.isBrowser('ie', true) && !browser.isBrowser('edge', true)) {
        window.addEventListener('mousemove', this.mousemove)
      }
    },
    methods: {
      mousemove (event) {
        this.mouseX = (event.x - window.innerWidth / 2) * -1
        this.mouseY = (event.y - window.innerHeight / 2) * -1
      },
      parallax (speed) {
        return (
          'transform: translate3d(' +
          this.mouseX * 0.01 * speed +
          'px, ' +
          this.mouseY * 0.01 * speed +
          'px, 0);'
        )
      }
    },
    watch: {
      current (newVal, oldVal) {
        this.localId = newVal
      }
    }
  }

</script>

<style scope lang="scss">
  @import '../../../styles/variables';
  .illustration {
    position: absolute;
    top: 180px;
    height: 100%;
    top: 45px;
    
    .bg {
      position: absolute;
      top: 0px;
      right: 120px;
      z-index: 997;
    }
    .parallax {
      z-index: 996;
      top: 0;
      right: 0;
      max-width: 400px;
      transition: transform 100ms ease-out;
    }
    .picto {
      position: absolute;
      z-index: 998;
      &.__1 {
        top: 60px;
        right: 143px;
      }
      &.__2 {
        top: 65px;
        right: 146px;
      }
      &.__3 {
        top: 80px;
        right: 148px;
      }
      &.__4 {
        top: 50px;
        right: 148px;
      }
    }

    .preload {
      display: none;
    }

    @media #{$small} {
      display: none;
    }
  }
</style>